html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
    transform: translatey(-10px);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fillRight {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
@keyframes textUp {
  0% {
    top: 0;
  }
  20% {
    top: -2px;
  }
  40% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
.Chat__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Chat__list {
  width: 100%;
  height: 100%;
  max-width: 900px;
}
.Chat__recommend {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #FFFFFF !important;
}
.Chat__recommend__button {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
}
.Chat__recommend__button:hover {
  background-color: #D8D8D8 !important;
}
.Chat__recommend__errorButton {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
  border-color: red !important;
  color: red !important;
}
.Chat__recommend__errorButton:hover {
  background-color: #D8D8D8 !important;
}
.Chat__recommend__highlightButton {
  min-height: 32px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  color: black !important;
  white-space: nowrap;
  box-shadow: none !important;
  font-weight: 600;
  text-align: start;
  margin-right: 4px;
}
.Chat__recommend__highlightButton:hover {
  background-color: #D8D8D8 !important;
}
.Chat__textfield {
  position: absolute !important;
  bottom: 12px !important;
  box-sizing: border-box;
  width: calc(100% - 32px);
  max-width: 848px !important;
  padding: 0 8px;
  background-color: white;
  font-size: 15px;
  outline: none;
}
.Chat__textfield__button {
  border-radius: 16px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.Chat__greeting {
  background: #fff;
  border-radius: 10px;
  padding: 14px 16px;
}
.Chat__exampleWrap {
  display: flex;
  flex: 0 0 auto;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.Chat__example {
  display: flex;
  flex: 1;
  justify-content: flex-start !important;
  height: auto;
  padding: 12px !important;
  margin-bottom: 16px;
  background-color: #F5F5F5 !important;
  border: 1px solid #CCC !important;
  border-radius: 10px !important;
  color: black !important;
  box-shadow: none !important;
  align-items: center;
}
.Chat__example:hover {
  background-color: #D8D8D8 !important;
}
.Chat__example_mobile {
  display: flex;
  flex: 1;
  justify-content: flex-start !important;
  height: auto;
  padding: 14px 16px;
  margin-left: 44px;
  margin-bottom: 12px !important;
  background-color: #faefe8 !important;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
}
.Chat__example_mobile p {
  font-size: 15px;
  color: #ed672e;
}
.Chat__example_mobile:hover {
  background-color: #D8D8D8 !important;
}
.Chat__iconCommon, .Chat__iconExp, .Chat__icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  flex: 0 0 28px;
}
.Chat__icon {
  background-color: #ed672e;
}
.Chat__iconExp {
  background-color: #bb86fc;
}
.Chat__input {
  width: 100%;
  min-height: 70px;
  padding: 16px 24px;
  background: #ffffff;
}
.Chat__inputmd {
  width: 100%;
  max-width: 900px;
  height: 74px;
  background: #ffffff;
  padding: 16px;
  padding-bottom: 10px;
}
.Chat__inputfield fieldset {
  top: -10px;
}
.Chat__input1, .Chat__input1__block {
  width: 100%;
  max-width: 900px;
  min-height: 48px;
  margin: auto;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Chat__input1:focus-within, .Chat__input1__block:focus-within {
  border: 2px solid #ed672e;
}
.Chat__input1__block {
  background: #d8d8d8;
}
.Chat__inputfield1 {
  width: 100%;
  min-width: 230px;
  max-width: 870px;
  font-weight: 500;
  color: #222;
  padding: 15px;
  border: none;
  background: none;
  line-height: 1;
}
.Chat__inputfield1:focus-visible {
  outline: none;
  border: none;
}
.Chat__inputButtonWrap {
  width: 70px;
  display: flex;
  justify-content: center;
}
.Chat__inputButton {
  width: 18px;
  border: none;
  color: #888;
  font-size: 18px;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}
.Chat__flex, .Chat__flex__col {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Chat__flex__col {
  flex-direction: column;
}
.Chat__chatTopWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Chat__container {
  width: 100%;
  max-width: 900px;
  height: 100%;
  padding: 0 24px;
}
@media (max-width: 600px) {
  .Chat__container {
    padding: 0 16px;
  }
}
.Chat__box, .Chat__box__p16 {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #CCC;
  border-radius: 10px;
  margin: 16px 0;
}
.Chat__box__p16 {
  padding: 16px;
}
.Chat__box:focus-visible, .Chat__box__p16:focus-visible {
  border: 2px solid #154cca;
}
.Chat__side {
  width: 240px;
  height: 100%;
}
@media (max-width: 1080px) {
  .Chat__side {
    width: 0;
  }
}
.Chat__main {
  width: 100%;
  height: calc(100vh - 120px);
  padding: 16px 24px;
  overflow-y: scroll;
}
@media (max-width: 1080px) {
  .Chat__main {
    width: 100%;
    overflow-x: hidden;
    padding-top: 16px;
  }
}
.Chat__mainDemo {
  width: 100%;
  height: calc(100vh - 140px - 66px);
  padding: 0 24px;
  overflow-y: scroll;
}
@media (max-width: 1080px) {
  .Chat__mainDemo {
    width: 100%;
    height: calc(100vh - 140px - 66px - 48px);
    overflow-x: hidden;
    padding-top: 0px;
  }
}
.Chat__mainmd {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: scroll;
}
.Chat__greetingWrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}
.Chat__greetingContent {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 14px 16px;
}
.Chat__greetingmd {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}
.Chat__chatWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  height: calc(100% - 120px);
}
@media (max-width: 1080px) {
  .Chat__chatWrap {
    padding-left: 0px;
  }
}
.Chat__chatWrapDemo {
  width: 100%;
  height: calc(100vh - 120px);
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1080px) {
  .Chat__chatWrapDemo {
    padding-left: 0px;
  }
}
.Chat__inlineBtn {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  margin-top: 16px;
}
.Chat__inlineBtn ::-webkit-scrollbar {
  display: none;
}
.Chat__back {
  width: 100%;
  height: 115px;
  flex-shrink: 0;
}
.Chat__backmd {
  width: 100%;
  height: 120px;
  flex-shrink: 0;
}
.Chat__bottom, .Chat__bottommd {
  width: 100%;
  min-height: 120px;
  position: absolute;
  bottom: 0;
  background: #ffffff;
}
.Chat__bottommd {
  left: 0;
  height: 120px;
  background: #f7f7f7;
}
.Chat__toggleGroup {
  width: 100%;
  text-align: center;
  padding-top: 24px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1080px) {
  .Chat__toggleGroup {
    padding-top: 60px;
  }
}
.Chat__toggleGroupDemo {
  width: 100%;
  text-align: center;
  padding-top: 24px;
  display: flex;
  justify-content: center;
}
.Chat__toggleBack {
  width: 216px;
  background: #eeeeee;
  border-radius: 10px;
  padding: 4px;
}
.Chat__toggle {
  width: 100px;
  background: #eeeeee;
  border: none;
  border-radius: 10px;
  padding: 8px 0;
  margin: 0 2px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: #000;
}
.Chat__bottombtn {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: #fff;
  color: #222;
  padding: 6px 12px;
  margin-left: 44px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.Chat__footer {
  width: 100%;
  height: 46px;
  background: #fff;
  text-align: center;
}
.Chat__footer a {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #999;
}
.Chat__infoModal {
  position: absolute;
  top: 0px;
  left: -210px;
  width: 200px;
  height: 150px;
  background: #d8d8d8;
  border-radius: 10px;
  padding: 8px 16px;
  color: #777;
  font-size: 14px;
  text-align: center;
}
.Chat__infoModal::after {
  content: "";
  position: absolute;
  top: 16px;
  right: -22px;
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 12px solid #d8d8d8;
  border-right: 12px solid transparent;
}
@media screen and (max-width: 1400px) {
  .Chat__infoModal::after {
    left: -22px;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid #d8d8d8;
  }
}
@media screen and (max-width: 1400px) {
  .Chat__infoModal {
    left: 40px;
  }
}
.Chat__modalTail {
  position: absolute;
  right: -16px;
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #eee;
  border-left: 8px solid #eee;
  border-right: 8px solid transparent;
}
.Chat__progressWrap {
  width: 80px;
  height: 10px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
}
.Chat__progress {
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #ed672e 0 80%, #eee 100% 100%);
  border-radius: 10px;
  animation: fillRight 10s ease-in-out 1;
}
.Chat__progressTextWrap {
  width: -moz-fit-content;
  width: fit-content;
  height: 28px;
}
.Chat__progressText {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}
.Chat__progressText span {
  font-size: 8px;
  color: #bbb;
  position: relative;
  animation: textUp 1.2s linear infinite;
}
.Chat__progressText span:nth-of-type(1) {
  animation-delay: 0s;
}
.Chat__progressText span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.Chat__progressText span:nth-of-type(3) {
  animation-delay: 0.4s;
}
.Chat__progressText span:nth-of-type(4) {
  animation-delay: 0.6s;
}
.Chat__progressText span:nth-of-type(5) {
  animation-delay: 0.8s;
}

.Guide__base {
  position: absolute;
}
.Guide__bubbleframe {
  max-width: 900px;
  z-index: 10;
  animation: fade 0.8s linear 1;
}
.Guide__bubble1Wrap {
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .Guide__bubble1Wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.Guide__bubble2Wrap {
  position: absolute;
  top: -100px;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .Guide__bubble2Wrap {
    top: -140px;
  }
}
.Guide__bubble1 {
  width: 300px;
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .Guide__bubble1 {
    width: 280px;
  }
}
.Guide__bubble1__img {
  background-image: url("../../images/bubble1.png");
  width: 300px;
  height: 150px;
  padding: 16px 40px 24px 20px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: start;
}
@media screen and (max-width: 768px) {
  .Guide__bubble1__img {
    background-image: url("../../images/bubble1_md.png");
    width: 280px;
    height: 100px;
    padding-right: 38px;
  }
}
.Guide__bubble1 p {
  margin: 0;
  font-size: 16px;
  color: #fff;
  word-break: keep-all;
}
.Guide__bubble1 button {
  width: 68px;
  height: 24px;
  font-size: 16px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
}
.Guide__bubble2 {
  width: 300px;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .Guide__bubble2 {
    width: 280px;
  }
}
.Guide__bubble2__img {
  background-image: url("../../images/bubble2.png");
  width: 300px;
  height: 150px;
  padding: 16px 32px 20px 32px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: start;
}
@media screen and (max-width: 768px) {
  .Guide__bubble2__img {
    background-image: url("../../images/bubble2_md.png");
    width: 280px;
    height: 100px;
    padding-right: 20px;
  }
}
.Guide__bubble2 p {
  width: 100%;
  margin: 0;
  font-size: 16px;
  color: #fff;
  word-break: keep-all;
}
.Guide__bubble2 button {
  width: 60px;
  height: 24px;
  font-size: 16px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
}
.Guide__tipContent {
  text-align: center;
  padding: 4px;
  word-break: keep-all;
  color: #fff;
}
.Guide__tipContent h4 {
  margin: 8px 0;
}
.Guide__tipContent p {
  font-size: 14px;
}

.Sidebar {
  width: 240px;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #202023;
  z-index: 9999;
}
.Sidebar__productList {
  position: relative;
  top: 12px;
  height: calc(100vh - 120px);
  background-color: transparent !important;
}
.Sidebar__productList__product {
  position: relative;
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  max-width: 218px;
  min-height: 40px !important;
  padding: 8px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  color: #DDDDDD !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left;
  font-size: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.Sidebar__productList__product::after {
  content: "";
  position: absolute;
  width: 48px;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(to right, transparent, #202023 90%);
}
.Sidebar__productList__product:hover {
  background-color: #3B3B3B !important;
}
.Sidebar__button {
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  height: 36px;
  padding: 8px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left;
  font-size: 14px !important;
}
.Sidebar__button:hover {
  background-color: #3B3B3B !important;
}

.Topbar {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #34343f;
  z-index: 9999;
}
.Topbar__button {
  top: 8px;
  right: 8px;
  height: 32px;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #CCCCCC !important;
  box-shadow: none !important;
  font-size: 14px !important;
}
.Topbar__button:hover {
  color: #FFFFFF !important;
}

.Modal__hyperClova {
  position: absolute;
  bottom: 24px !important;
  left: 24px !important;
  width: 160px;
  height: auto;
  background-color: #F5F5F5 !important;
  border: none !important;
}
.Modal__button {
  position: absolute;
  bottom: 24px !important;
  right: 24px !important;
  padding: 8px;
  font-size: 12px !important;
  background-color: white !important;
  border: none !important;
}

.Util__bold {
  font-weight: 600;
}
.Util__textStart {
  text-align: start;
}
.Util__w100mw900 {
  width: 100%;
  max-width: 900px;
}/*# sourceMappingURL=Chat.module.css.map */