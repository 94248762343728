.Message {
	display: flex;
	align-items: flex-start;

	&__list {
		display: flex;
		align-items: flex-start;
		// border: 1px solid #ccc;
		// border-radius: 10px;
		padding-bottom: 16px;
		// margin-bottom: 16px;

		&__highlight {
			@extend .Message__list;
			border: 2.5px solid #154ccb;
		}
	}
	
	&__button {
		height: 32px;
		border-radius: 32px !important;
		font-size: 16px !important;
		box-shadow: none !important;
		margin-top: 4px !important;
		background-color: #FFFFFF;
	}

	&__iconCommon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 8px;
		margin-right: 16px;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		color: white;
		object-fit: cover;
		//font-weight: bold;
		//font-size: 16px;
	}
	
	&__icon {
		@extend .Message__iconCommon;
		background-color: #ed672e;
	}
	
	&__iconExp {
		@extend .Message__iconCommon;
		background-color: #bb86fc;
	}

	&__myIcon {
		@extend .Message__iconCommon;
		background-color: #e2e2e2;
	}
	
	&__myIconExp {
		@extend .Message__iconCommon;
		background-color: #e2dfec;
	}

	&__content {
		width: 100%;
		background: #fff;
		border-radius: 10px;
		padding: 14px 16px;
		flex: 1;
		align-items: center;
		justify-content: center;

		&__my {
			@extend .Message__content;
			background: #eee;
		}
	}

	&__text {
		// padding: 8px;
		// border-radius: 8px !important; 
		// background-color: transparent;
		color: #222;
		word-wrap: break-word;
		white-space: pre-wrap;
		// line-height: 1.4;

		// p {
		// 	line-height: 1.4;
		// }
	}
	
	&__text a {
		color: #154CCB;
		text-decoration: underline;
		word-break: break-all;
	}

	&__my {
		&__text {
			padding: 8px;
			border-radius: 8px !important;
			background-color: transparent;
			color: #4D4D4D;
			text-align: left;
			word-wrap: break-word;
			white-space: pre-wrap;
		}
	}

	&__btnGroup {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0 16px 8px 44px;
		overflow-x: scroll;
	}

	&__actionBtn {
		width: fit-content;
		min-width: fit-content;
		height: fit-content;
		border: none;
		border-radius: 10px;
		background: #fff;
		color: #222;
		padding: 6px 12px;
		margin: 0 4px 0 0;
		font-size: 14px;
		font-weight: 600;
		text-decoration: none;

		@media screen and (min-width: 600px) {
			font-size: 15px;
		}
	}
	
	&__guideWrapper {
		width: 100%;
		background: rgba(21,76,202, 1);
		border: 1px solid rgb(21,76,202);
		border-radius: 10px;
		color: #fff;
		padding: 16px;
		text-align: center;
		// box-shadow: 3px 3px 5px #555;
		margin-bottom: 16px;
		display: flex;

		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
	}

	&__contentWrapper {
		width: 75%;
		padding-left: 16px;
		text-align: start;

		@media screen and (max-width: 768px) {
			width: 100%;
			padding: 0;
		}
	}
	
	&__linkWrapper {
		width: 25%;
		margin-bottom: 8px;
		font-size: 20px;
		font-weight: 800;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		@media screen and (max-width: 768px) {
			width: 100%;
			font-size: 16px;
		}
	}
	
	&__link {
		width: 90%;
		height: fit-content;
		padding: 8px 0;
		background: #66b3f7;
		border-radius: 10px;
		text-decoration: none;
		color: #fff;

		@media screen and (max-width: 768px) {
			width: 100%;
			margin-top: 8px;
		}
	}
	
	&__headline {
		margin: 8px 0;
		margin-bottom: 16px;
		font-size: 20px;
		font-weight: 800;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			word-break: keep-all;
			margin-bottom: 0;
		}
	}
	
	&__footer {
		margin: 8px 0;
		font-size: 16px;
		font-weight: 800;
		color: #ccc;

		@media screen and (max-width: 768px) {
			font-size: 12px;
		}
	}
}

.Carousel {
	&__wrap {
		width: 300px;
		height: 400px;
		background: #d8d8d8;
	}

	&__topWrap {
		width: 100%;
		height: 100px;
	}

	&__imgWrap {
		width: 300px;
		height: 300px;
		overflow: hidden;

		img {
			width: 50%;
			height: 300px;
		}
	}
}