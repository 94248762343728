
.onboarding-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 10000;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.modal-frame {
	width: 100vw;
	flex: 1 0 auto;
	border-radius: 0;
	background-color: #fff;
	text-align: left;
	overflow: hidden;
	
	@media screen and (min-width: 768px) {
		flex: none;
		width: 500px;
		height: 650px;
		border-radius: 10px;
	}
}

.modal-container {
	width: 300vw;
	height: 100%;
	transition: all 0.5s;
	display: flex;

	@media screen and (min-width: 768px) {
		width: 1500px;
	}
}

.modal {
	width: 100vw;
	height: 100%;
	overflow-y: scroll;

	@media screen and (min-width: 768px) {
		width: 500px;
	}
}

.modal-img1 {
	background-image: url('../../images/onboard1_md.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 400px;

	@media screen and (min-width: 768px) {
		background-image: url('../../images/onboard1.png');
	}
}

.modal-img2 {
	background-image: url('../../images/onboard2_md.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 400px;
	
	@media screen and (min-width: 768px) {
		background-image: url('../../images/onboard2.png');
	}
}

.modal-img3 {
	background-image: url('../../images/onboard3.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 300px;

	@media screen and (min-height: 700px) {
		height: 350px;
	}
}

.modal-headline {
	color: #555;
	font-weight: 900;
	font-size: 24px;
	margin: 32px;
	margin-top: 20px;

	@media screen and (min-height: 700px) {
		margin: 40px 44px;
	}
}

.modal-contents {
	color: #555;
	font-size: 16px;
	line-height: 1.5;
	display: none;
	margin: 0 32px;
	margin-bottom: 16px;

	@media screen and (min-height: 700px) {
		margin: 0 44px;
		margin-bottom: 16px;
		display: block;
	}
}

.modal-button {
	width: 40px;
	height: 40px;
	border: none;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.5);
	margin: 0 8px;
	font-size: 16px;
	font-weight: 900;
	display: none;

	@media screen and (min-width: 768px) {
		display: block;	
	}
}

.start-button {
	width: 90%;
	height: 45px;
	border: none;
	border-radius: 10px;
	background: #154ccb;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	box-shadow: 3px 3px 6px #999;
	margin: 32px 0;
}

.indicator-wrap {
	flex: 0 0 auto;
	width: 100%;
	height: 40px;
	text-align: center;

	@media screen and (min-width: 768px) {
		display: none;
	}
}

.indicator-dot {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.5);
	margin: 0 4px;
}

.f-row {
	display: flex;
}

.f-col {
	display: flex;
	flex-direction: column;
}

.f-center {
	align-items: center;
	justify-content: center;
}

.f-spaceAround {
	align-items: center;
	justify-content: space-around;
}

.w100 {
	width: 100%;
}

.h100 {
	height: 100%;
}

.invisible {
	visibility: hidden;
}

.hide {
	display: none;
}

.letter-tight {
	letter-spacing: -1px;
}