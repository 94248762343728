@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css);
html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: none;
  color: #222;
}

body, #root {
  width: 100vw;
  height: 100%;
  font-family: "NanumSquare";
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, span, button, a {
  box-sizing: border-box;
  font-family: "NanumSquare";
  line-height: 1.4;
  font-size: 15px;
  color: #222;
}

input {
  box-sizing: border-box;
  font-family: "NanumSquare";
  line-height: 1.4;
  font-size: 16px;
  color: #222;
}

p, h1, h2, h3, h4, h5, h6, div, span {
  word-break: keep-all;
  font-family: "NanumSquare";
  font-size: 15px;
  line-height: 1.4;
  margin: 0;
}

a {
  text-decoration: none;
}

hr {
  border-width: 0.5px;
}

.style.Chat__inputfield1:focus-visible {
  outline: 0px;
}

:focus-visible {
  outline: 2px solid purple;
}

/* width와 height는 스크롤바의 크기를 지정합니다. */
/* thumb-radius는 스크롤바 위아래 버튼의 크기를 지정합니다. */
/* track-color는 스크롤바 바탕 색상을 지정합니다. */
/* thumb-color는 스크롤바 버튼 색상을 지정합니다. */
/* thumb-active-color는 스크롤바 버튼을 클릭할 때 나타나는 색상을 지정합니다. */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0);
}/*# sourceMappingURL=global.css.map */