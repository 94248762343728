.Message {
  display: flex;
  align-items: flex-start;
}
.Message__list, .Message__list__highlight {
  display: flex;
  align-items: flex-start;
  padding-bottom: 16px;
}
.Message__list__highlight {
  border: 2.5px solid #154ccb;
}
.Message__button {
  height: 32px;
  border-radius: 32px !important;
  font-size: 16px !important;
  box-shadow: none !important;
  margin-top: 4px !important;
  background-color: #FFFFFF;
}
.Message__iconCommon, .Message__myIconExp, .Message__myIcon, .Message__iconExp, .Message__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  -o-object-fit: cover;
     object-fit: cover;
}
.Message__icon {
  background-color: #ed672e;
}
.Message__iconExp {
  background-color: #bb86fc;
}
.Message__myIcon {
  background-color: #e2e2e2;
}
.Message__myIconExp {
  background-color: #e2dfec;
}
.Message__content, .Message__content__my {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 14px 16px;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.Message__content__my {
  background: #eee;
}
.Message__text {
  color: #222;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.Message__text a {
  color: #154CCB;
  text-decoration: underline;
  word-break: break-all;
}
.Message__my__text {
  padding: 8px;
  border-radius: 8px !important;
  background-color: transparent;
  color: #4D4D4D;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.Message__btnGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px 8px 44px;
  overflow-x: scroll;
}
.Message__actionBtn {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  background: #fff;
  color: #222;
  padding: 6px 12px;
  margin: 0 4px 0 0;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
@media screen and (min-width: 600px) {
  .Message__actionBtn {
    font-size: 15px;
  }
}
.Message__guideWrapper {
  width: 100%;
  background: rgb(21, 76, 202);
  border: 1px solid rgb(21, 76, 202);
  border-radius: 10px;
  color: #fff;
  padding: 16px;
  text-align: center;
  margin-bottom: 16px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .Message__guideWrapper {
    flex-direction: column;
  }
}
.Message__contentWrapper {
  width: 75%;
  padding-left: 16px;
  text-align: start;
}
@media screen and (max-width: 768px) {
  .Message__contentWrapper {
    width: 100%;
    padding: 0;
  }
}
.Message__linkWrapper {
  width: 25%;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media screen and (max-width: 768px) {
  .Message__linkWrapper {
    width: 100%;
    font-size: 16px;
  }
}
.Message__link {
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 0;
  background: #66b3f7;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .Message__link {
    width: 100%;
    margin-top: 8px;
  }
}
.Message__headline {
  margin: 8px 0;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .Message__headline {
    font-size: 16px;
    word-break: keep-all;
    margin-bottom: 0;
  }
}
.Message__footer {
  margin: 8px 0;
  font-size: 16px;
  font-weight: 800;
  color: #ccc;
}
@media screen and (max-width: 768px) {
  .Message__footer {
    font-size: 12px;
  }
}

.Carousel__wrap {
  width: 300px;
  height: 400px;
  background: #d8d8d8;
}
.Carousel__topWrap {
  width: 100%;
  height: 100px;
}
.Carousel__imgWrap {
  width: 300px;
  height: 300px;
  overflow: hidden;
}
.Carousel__imgWrap img {
  width: 50%;
  height: 300px;
}/*# sourceMappingURL=Message.module.css.map */