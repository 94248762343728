html, 
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	background-color: white !important;
	overflow-y: scroll;
}

.ChatRoomEntry {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100vh;
		width: 100%;
		overflow-y: scroll;
		padding: 0 16px;

		& > * {
			width: 100%;
		}
	}
	
	&__bannerTop {
		max-width: calc(600px - 48px);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 50px;
		margin-bottom: 35px;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	
	&__bannerBottom {
		max-width: calc(600px - 48px);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-bottom: 1.2rem;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	
	&__kakaobutton {
		max-width: calc(600px - 48px);
		width: 100%;
		height: 4.8rem;
		margin-bottom: 50px !important;
		background-color: #FEE500 !important;
		border-radius: 10px !important;
	}
	

	
	&__buttonGroup {
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: calc(600px - 48px);
		justify-content: space-between;
	}
	
	&__button {
		width: 100%;
		height: 135px;
		font-size: 1.5vw;
		border: none;
		// border: 3px solid #e0edf6;
		border-radius: 10px;
		// background: #ffffff;
		background: linear-gradient(135deg, #6596f4, #05adf1);
		color: #ffffff;
		// color: #6596f4;
		// color: #000000;
		box-shadow: 1px 1px 5px #999999;
		text-align: left;
		font-weight: bold;
		padding: 32px 16px 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		&:hover {
			opacity: 0.5;
			cursor: pointer;
			// background: #6596f4;
			// color: #e0edf6;
		}
		
		@media (max-width: 600px) {
			padding: 32px 8px;
		}
		
		@media (max-width: 800px) {
			font-size: 12px;
		}
		
		@media (min-width: 1080px) {
			font-size: 16px;
		}
	}
	
	&__text {
		width: 100%;
	}
	
	&__logoWrap {
		width: 100%;
		// height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}
}
