html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: white !important;
	overflow-x: hidden;
	overflow-y: hidden;
}

@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}

@include keyframes(fade){
	0%{
		opacity: 0;
	}
	50%{
		opacity: 0.5;
		transform: translatey(-10px);
	}
	100%{
		opacity: 1;
	}
}

@include keyframes(fillRight){
	0%{
		width: 0;
	}
	50%{
		width: 50%;
	}
	100%{
		width: 100%;
	}
}

@include keyframes(textUp) {
	0%{
		top: 0;
	}
	20%{
		top: -2px;
	}
	40%{
		top: 0;
	}
	100%{
		top: 0;
	}
}

.Chat {	
	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	&__list {
		width: 100%;
		height: 100%;
		max-width: 900px;
		// padding-bottom: 180px;
	}
	
	&__recommend {
		width: fit-content;
		background-color: #FFFFFF !important;
		
		@mixin button-style {
			min-height: 32px;
			background-color: #F5F5F5 !important;
			border: 1px solid #CCC !important;
			border-radius: 32px !important;
			font-size: 14px !important;
			color: black !important;
			white-space: nowrap;
			box-shadow: none !important;
			font-weight: 600;
			text-align: start;
			margin-right: 4px;
			
			&:hover {
				background-color: #D8D8D8 !important;
			}
		}

		&__button {
			@include button-style;
		}
		
		&__errorButton {
			@include button-style;
			border-color: red !important;
			color: red !important;
		}
		
		&__highlightButton {
			@include button-style;
			//border: 2px solid #154CCB !important;
		}
	}
		
	&__textfield {
		position: absolute !important;
		bottom: 12px !important;
		box-sizing: border-box;
		width: calc(100% - 32px);
		//width: 100%;
		max-width: 848px !important;
		//resize: none;
		padding: 0 8px;
		background-color: white;
		font-size: 15px;
		outline: none;
		
		&__button {
			border-radius: 16px;
			background-color: transparent !important;
			box-shadow: none !important;
		}
	}

	&__greeting {
		background: #fff; 
		border-radius: 10px; 
		padding: 14px 16px;
	}

	&__exampleWrap {
		display: flex;
		flex: 0 0 auto;
		column-gap: 8px;
	}
	
	&__example {
		display: flex;
		flex: 1;
  		justify-content: flex-start !important;
  		height: auto;
		padding: 12px !important;
		margin-bottom: 16px;
		background-color: #F5F5F5 !important;
		border: 1px solid #CCC !important;
		border-radius: 10px !important;
		color: black !important;
		box-shadow: none !important;
		align-items: center;
		
		&:hover {
			background-color: #D8D8D8 !important;
		}
	}
	
	&__example_mobile {
		display: flex;
		flex: 1;
  		justify-content: flex-start !important;
  		height: auto;
		padding: 14px 16px;
		margin-left: 44px;
		margin-bottom: 12px !important;
		background-color: #faefe8 !important;
		border: none;
		border-radius: 10px !important;
		box-shadow: none !important;
		p {
			font-size: 15px;
			color: #ed672e;
		}
		
		&:hover {
			background-color: #D8D8D8 !important;
		}
	}
	
	&__iconCommon {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 8px;
		margin-right: 16px;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		object-fit: cover;
		flex: 0 0 28px;
		//font-weight: bold;
		//font-size: 16px;
	}
	
	&__icon {
		@extend .Chat__iconCommon;
		background-color: #ed672e;
	}
	
	&__iconExp {
		@extend .Chat__iconCommon;
		background-color: #bb86fc;
	}
	
	&__input {
		width: 100%;
		min-height: 70px;
		padding: 16px 24px;
		background: #ffffff;
	}
	
	&__inputmd {
		width: 100%;
		max-width: 900px;
		height: 74px;
		background: #ffffff;
		padding: 16px;
		padding-bottom: 10px;
	}
	
	&__inputfield {
		& fieldset {
			top: -10px;
		}
	}

	&__input1 {
		width: 100%;
		max-width: 900px;
		min-height: 48px;
		margin: auto;
		// padding: 12px 14px;
		background: #f5f5f5;
		// border: 1px solid #ccc;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:focus-within {
			border: 2px solid #ed672e;
		}

		&__block {
			@extend .Chat__input1;
			background: #d8d8d8;
		}
	}

	&__inputfield1 {
		width: 100%;
		min-width: 230px;
		max-width: 870px;
		// font-size: 16px;
		font-weight: 500;
		color: #222;
		padding: 15px;
		border: none;
		background: none;
		line-height: 1;

		&:focus-visible {
			outline: none;
			border: none;
		}
	}

	&__inputButtonWrap {
		width: 70px;
		display: flex;
		justify-content: center;
	}

	&__inputButton {
		width: 18px;
		border: none;
		color: #888;
		font-size: 18px;
		background-color: transparent;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0;
	}
	
	&__flex {
		width: 100%;
		display: flex;
		justify-content: center;
		
		&__col {
			@extend .Chat__flex;
			flex-direction: column;
		}
	}

	&__chatTopWrap {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	
	&__container {
		width: 100%;
		max-width: 900px;
		height: 100%;
		padding: 0 24px;
		
		@media (max-width: 600px) {
			padding: 0 16px;
		}
	}
	
	&__box {
		width: 100%;
		min-height: 70px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid #CCC;
		border-radius: 10px;
		margin: 16px 0;
		
		&__p16 {
			@extend .Chat__box;
			padding: 16px;
		}

		&:focus-visible {
			border: 2px solid #154cca;
		}
	}
	
	&__side {
		width: 240px;
		height: 100%;

		@media (max-width: 1080px) {
			width: 0;
		}
	}
	
	&__main {
		width: 100%;
		height: calc(100vh - 120px);
		padding: 16px 24px;
		overflow-y: scroll;
		
		@media (max-width: 1080px) {
			width: 100%;
			overflow-x: hidden;
			padding-top: 16px;
		}
	}

	&__mainDemo {
		width: 100%;
		height: calc(100vh - 140px - 66px);
		padding: 0 24px;
		overflow-y: scroll;
		
		@media (max-width: 1080px) {
			width: 100%;
			height: calc(100vh - 140px - 66px - 48px);
			overflow-x: hidden;
			padding-top: 0px;
		}
	}
	
	&__mainmd {
		width: 100%;
		height: 100%;
		padding: 16px;
		overflow-y: scroll;
	}

	&__greetingWrap {
		width: 100%;
		height: auto;
		display: flex;
		align-items: flex-start;
		// border: 1px solid #ccc;
		// border-radius: 10px;
		// margin-bottom: 8px;
		margin: 16px 0;
	}

	&__greetingContent {
		width: 100%;
		background: #fff; 
		border-radius: 10px; 
		padding: 14px 16px;
	}

	&__greetingmd {
		width: 100%;
		height: auto;
		display: flex;
		align-items: flex-start;
		// border: 1px solid #ccc;
		// border-radius: 10px;
		// margin-bottom: 8px;
		margin: 16px 0;
	}
	
	&__chatWrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		background: #f7f7f7;
		height: calc(100% - 120px);
		// padding-top: 32px;
		
		@media (max-width: 1080px) {
			padding-left: 0px;
		}
	}
	
	&__chatWrapDemo {
		width: 100%;
		height: calc(100vh - 120px);
		background: #f7f7f7;
		display: flex;
		flex-direction: column;
		
		@media (max-width: 1080px) {
			padding-left: 0px;
		}
	}
	
	&__inlineBtn {
		width: 100%;
		display: flex;
		overflow-x: scroll;
		margin-top: 16px;

		::-webkit-scrollbar {
			display: none;
		}
	}
	
	&__back {
		width: 100%;
		height: 115px;
		flex-shrink: 0;
	}

	&__backmd {
		width: 100%;
		height: 120px;
		flex-shrink: 0;
	}
	
	&__bottom {
		width: 100%;
		min-height: 120px;
		position: absolute;
		bottom: 0;
		// left: 240px;
		background: #ffffff;
		// flex: 1 0 auto;
	}
	
	&__bottommd {
		@extend .Chat__bottom;
		left: 0;
		height: 120px;
		background:#f7f7f7;
	}
	
	&__toggleGroup {
		width: 100%;
		text-align: center;
		padding-top: 24px;
		display: flex;
		justify-content: center;
		
		@media (max-width: 1080px) {
			padding-top: 60px;
		}
	}

	&__toggleGroupDemo {
		width: 100%;
		text-align: center;
		padding-top: 24px;
		display: flex;
		justify-content: center;
	}
	
	&__toggleBack {
		width: 216px;
		background: #eeeeee;
		border-radius: 10px;
		padding: 4px;
	}
	
	&__toggle {
		width: 100px;
		background: #eeeeee;
		border: none;
		border-radius: 10px;
		padding: 8px 0;
		margin: 0 2px;
		font-size: 12px;
		font-weight: bold;
		cursor: pointer;
		color: #000;
	}

	&__bottombtn {
		width: fit-content;
		min-width: fit-content;
		height: fit-content;
		border: none;
		border-radius: 10px;
		background: #fff;
		color: #222;
		padding: 6px 12px;
		margin-left: 44px;
		font-size: 14px;
		font-weight: 600;
		text-decoration: none;
	}

	&__footer {
		width: 100%;
		height: 46px;
		background: #fff;
		text-align: center;
		
		a {
			font-weight: 500;
			font-size: 12px;
			line-height: 1;
			color: #999
		}
	}

	&__infoModal {
		position: absolute;
		top: 0px;
		left: -210px;
		width: 200px;
		height: 150px;
		background: #d8d8d8;
		border-radius: 10px;
		padding: 8px 16px;
		color: #777;
		font-size: 14px;
		text-align: center;

		&::after {
			content: '';
			position: absolute;
			top: 16px;
			right: -22px;
			width: 0;
			height: 0;
			border-bottom: 6px solid transparent;
			border-top: 6px solid transparent;
			border-left: 12px solid #d8d8d8;
			border-right: 12px solid transparent;

			@media screen and (max-width: 1400px) {
			left: -22px;

			border-bottom: 6px solid transparent;
			border-top: 6px solid transparent;
			border-left: 12px solid transparent;
			border-right: 12px solid #d8d8d8;
		}
		}

		@media screen and (max-width: 1400px) {
			left: 40px;
		}
	}

	&__modalTail {
		position: absolute;
		right: -16px;
		width: 0;
		height: 0;
		border-bottom: 8px solid transparent;
		border-top: 8px solid #eee;
		border-left: 8px solid #eee;
		border-right: 8px solid transparent;
	}

	&__progressWrap {
		width: 80px;
		height: 10px;
		background-color: #eee;
		border-radius: 10px;
		overflow: hidden;
	}

	&__progress {
		width: 100%;
		height: 10px;
		background: linear-gradient(to right, #ed672e 0 80%, #eee 100% 100%);
		border-radius: 10px;

		@include animate(fillRight, 10s, ease-in-out, 1);
	}

	&__progressTextWrap {
		width: fit-content;
		height: 28px;
	}

	&__progressText{
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 16px;

		span {
			font-size: 8px;
			color: #bbb;
			position: relative;
			@include animate(textUp, 1.2s, linear, infinite);
		}
		
		span:nth-of-type(1) {
			animation-delay: 0s;
		}

		span:nth-of-type(2) {
			animation-delay: 0.2s;
		}

		span:nth-of-type(3) {
			animation-delay: 0.4s;
		}

		span:nth-of-type(4) {
			animation-delay: 0.6s;
		}

		span:nth-of-type(5) {
			animation-delay: 0.8s;
		}
	}
}



.Guide {
	&__base {
		position: absolute;
	}

	&__bubbleframe {
		max-width: 900px;
		z-index: 10;
		
		@include animate(fade, 0.8s, linear, 1);
	}
	
	&__bubble1Wrap {
		position: relative;
		top: 0;
		left: 0;
		z-index: 10;

		@media screen and (max-width: 768px) {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}
	
	&__bubble2Wrap {
		position: absolute;
		top: -100px;
		z-index: 10;

		@media screen and (min-width: 768px) {
			top: -140px;
		}
	}
	
	&__bubble1 {
		width: 300px;
		margin-top: 16px;
		// transform: translateX(-4px);

		@media screen and (max-width: 768px) {
			width: 280px;
		}
		
		&__img {
			background-image: url("../../images/bubble1.png");
			width: 300px;
			height: 150px;
			padding: 16px 40px 24px 20px;
			background-size: contain;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-around;
			text-align: start;

			@media screen and (max-width: 768px) {
				background-image: url("../../images/bubble1_md.png");
				width: 280px;
				height: 100px;
				padding-right: 38px;
			}
		}
		
		p {
			margin: 0;
			font-size: 16px;
			color: #fff;
			word-break: keep-all;
		}
		
		button {
			width: 68px;
			height: 24px;
			font-size: 16px;
			background: none;
			border: 1px solid #fff;
			border-radius: 10px;
			color: #fff;
		}
	}
	
	&__bubble2 {
		width: 300px;
		z-index: 10;
		// transform: translateX(-205px) translateY(50px);

		@media screen and (max-width: 768px) {
			width: 280px;
		}
		
		&__img {
			background-image: url("../../images/bubble2.png");
			width: 300px;
			height: 150px;
			padding: 16px 32px 20px 32px;
			background-size: contain;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-around;
			text-align: start;

			@media screen and (max-width: 768px) {
				background-image: url("../../images/bubble2_md.png");
				width: 280px;
				height: 100px;	
				padding-right: 20px;
			}
		}
		
		p {
			width: 100%;
			margin: 0;
			font-size: 16px;
			color: #fff;
			word-break: keep-all;
		}
		
		button {
			width: 60px;
			height: 24px;
			font-size: 16px;
			background: none;
			border: 1px solid #fff;
			border-radius: 10px;
			color: #fff;
		}
	}
	
	&__tipContent {
		text-align: center;
		padding: 4px;
		word-break: keep-all;
		color: #fff;
		
		h4 {
			margin: 8px 0;
		}
		
		p {
			font-size: 14px;
		}
	}
}

.Sidebar {
	width: 240px;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	background-color: #202023;
	z-index: 9999;
	
	&__productList {
		position: relative;
		top: 12px;
		height: calc(100vh - 120px);
		background-color: transparent !important;
		
		&__product {
			position: relative;
			left: 8px;
			right: 8px;
			//bottom: 0.75rem;
			width: calc(100% - 16px);
			max-width: 218px;
			min-height: 40px !important;
			padding: 8px !important;
			border-radius: 6px !important;
			background-color: transparent !important;
			color: #DDDDDD !important;
			box-shadow: none !important;
			
			justify-content: flex-start !important;
			align-items: center !important;
			text-align: left;
			font-size: 14px !important;
			white-space: nowrap;
  			overflow: hidden;
  			text-overflow: ellipsis !important;
			
			&::after {
				content: "";
				position: absolute;
				width: 48px;
				height: 100%;
				right: 0;
				top: 0;
				background: linear-gradient(to right, transparent, #202023 90%);
			}
			
			&:hover {
				background-color: #3B3B3B !important;
			}
		}
	}
	
	&__button {
		left: 8px;
		right: 8px;
		//bottom: 0.75rem;
		width: calc(100% - 16px);
		height: 36px;
		padding: 8px !important;
		border-radius: 6px !important;
		background-color: transparent !important;
		color: #FFFFFF !important;
		box-shadow: none !important;
		
		justify-content: flex-start !important;
		align-items: center !important;
		text-align: left;
		font-size: 14px !important;		
		
		&:hover {
			background-color: #3B3B3B !important;
		}
	}
}

.Topbar {
	width: 100%;
	height: 48px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #34343f;
	z-index: 9999;
	
	&__button {
		top: 8px;
		right: 8px;
		height: 32px;
		border-radius: 4px !important;
		background-color: transparent !important;
		color: #CCCCCC !important;
		//padding: 0.5;
		box-shadow: none !important;
		font-size: 14px !important;
		
		&:hover {
			color: #FFFFFF !important;
		}
	}
}

.Modal {
	
	&__hyperClova {
		position: absolute;
		bottom: 24px !important;
		left: 24px !important;
		width: 160px;
		height: auto;
		background-color: #F5F5F5 !important;
		border: none !important;
	}
	
	&__button {
		position: absolute;
		bottom: 24px !important;
		right: 24px !important;
		padding: 8px;
		font-size: 12px !important;
		background-color: white !important;
		border: none !important;
	}
}

.Util {
	&__bold {
		font-weight: 600;
	}

	&__textStart {
		text-align: start;
	}

	&__w100mw900 {
		width: 100%;
		max-width: 900px;
	}
}