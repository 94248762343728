html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white !important;
  overflow-y: scroll;
}

.ChatRoomEntry {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.ChatRoomEntry__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding: 0 16px;
}
.ChatRoomEntry__wrapper > * {
  width: 100%;
}
.ChatRoomEntry__bannerTop {
  max-width: 552px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 35px;
  background-color: #FFFFFF;
  border-radius: 10px;
}
.ChatRoomEntry__bannerBottom {
  max-width: 552px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.2rem;
  background-color: #FFFFFF;
  border-radius: 10px;
}
.ChatRoomEntry__kakaobutton {
  max-width: 552px;
  width: 100%;
  height: 4.8rem;
  margin-bottom: 50px !important;
  background-color: #FEE500 !important;
  border-radius: 10px !important;
}
.ChatRoomEntry__buttonGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 552px;
  justify-content: space-between;
}
.ChatRoomEntry__button {
  width: 100%;
  height: 135px;
  font-size: 1.5vw;
  border: none;
  border-radius: 10px;
  background: linear-gradient(135deg, #6596f4, #05adf1);
  color: #ffffff;
  box-shadow: 1px 1px 5px #999999;
  text-align: left;
  font-weight: bold;
  padding: 32px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ChatRoomEntry__button:hover {
  opacity: 0.5;
  cursor: pointer;
}
@media (max-width: 600px) {
  .ChatRoomEntry__button {
    padding: 32px 8px;
  }
}
@media (max-width: 800px) {
  .ChatRoomEntry__button {
    font-size: 12px;
  }
}
@media (min-width: 1080px) {
  .ChatRoomEntry__button {
    font-size: 16px;
  }
}
.ChatRoomEntry__text {
  width: 100%;
}
.ChatRoomEntry__logoWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}/*# sourceMappingURL=ChatRoomEntry.module.css.map */